import React from 'react'

/**
 * Originally written by https://github.com/bchiang7
 * File modified for this app
 *
 * External Icon (Square w/ Arrow)
 * @return {IconCopiedSuccessfully}
 */
const IconCopiedSuccessfully = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <title>Copied Successfully</title>
    <path opacity="0.4" d="M22 11.1V6.9C22 3.4 20.6 2 17.1 2H12.9C9.4 2 8 3.4 8 6.9V8H11.1C14.6 8 16 9.4 16 12.9V16H17.1C20.6 16 22 14.6 22 11.1Z" stroke="#0E1111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> <path d="M16 17.1V12.9C16 9.4 14.6 8 11.1 8H6.9C3.4 8 2 9.4 2 12.9V17.1C2 20.6 3.4 22 6.9 22H11.1C14.6 22 16 20.6 16 17.1Z" stroke="#0E1111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> <path d="M6.08008 14.9998L8.03008 16.9498L11.9201 13.0498" stroke="#0E1111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default IconCopiedSuccessfully
