const navbarContent = () => {
  return {
    'buttons': [
      'About',
      'Experience',
      'Projects',
      'Contact'
    ],
    'resume_cta': 'Resume'
  }
}
export default navbarContent
